<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">
        {{ $t('resource-config-upload-new-photo') }}
      </p>
    </header>
    <section class="modal-card-body">
      <div class="columns">
        <div class="column">
          <img v-if="currentImg" :src="currentImg" />
          <div v-else>{{ $t('resource-config-no-current-photo') }}</div>
        </div>
        <div class="column">
          <div>
            <b-field :label="$t('omw-resource-config-resource-id')">
              <b-input v-model="localResource.resourceId" disabled></b-input>
            </b-field>
            <b-field :label="$t('omw-resource-config-dataset')">
              <b-input v-model="localResource.datasetId" disabled></b-input>
            </b-field>
          </div>
          <div>
            <b-field :label="$t('omw-resource-config-resource-name')">
              <b-input v-model="localResource.nameOverride" disabled></b-input>
            </b-field>
          </div>
        </div>

        <div class="column is-8">
          <div v-if="imageName">
            <b-field grouped style="align-items: center">
              <b-field>
                <div class="buttons">
                  <b-button
                    type="is-primary"
                    icon-right="search-plus"
                    @click="zoomIn"
                  />
                  <b-button
                    type="is-primary"
                    icon-right="search-minus"
                    @click="zoomOut"
                  />
                </div>
              </b-field>
              <b-field horizontal>
                <b-tooltip
                  position="is-bottom"
                  :label="$t('omw-resource-config-drag-crop-instruction')"
                >
                  <b-switch
                    v-model="cropperMode"
                    true-value="Crop"
                    false-value="Move"
                    type="is-primary"
                    passive-type="is-primary"
                  >
                    {{ cropperMode }}
                  </b-switch>
                </b-tooltip>
              </b-field>
            </b-field>
            <vue-cropper
              :key="cropperKey"
              ref="cropper"
              style="max-height: 60vh"
              :src="imageName"
              :alt="$t('omw-resource-config-source-image')"
            >
            </vue-cropper>
          </div>
        </div>
      </div>
      <div>
        <b-field class="file is-primary">
          <b-upload v-model="file" accept="image/*">
            <span class="file-cta">
              <span class="file-label has-text-weight-semibold">{{
                $t('omw-resource-config-select-image')
              }}</span>
            </span>
          </b-upload>
          <span v-if="file" class="file-name">
            {{ file.name }}
          </span>
        </b-field>
      </div>
    </section>
    <footer class="modal-card-foot">
      <b-button
        icon-left="upload"
        :disabled="!newImage"
        type="is-primary"
        @click="upload()"
      >
        {{ $t('omw-resource-config-upload-button-label') }}
      </b-button>
      <b-button type="is-primary" @click="$parent.close()">
        {{ $t('omw-resource-config-close') }}
      </b-button>
    </footer>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import copy from 'fast-copy';
import FileType from 'file-type/browser';
import {
  getResourcePhoto,
  convertArrayBufferToImage,
} from '@/services/resourceConfig';

export default defineComponent({
  name: 'UploadResourcePhoto',
  components: {
    VueCropper,
  },
  props: {
    resource: {
      type: Object,
      required: false,
      default: () => {
        return {
          resourceId: '',
        };
      },
      // TODO: add validator
    },
    currentImg: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      file: undefined,
      newImage: undefined,
      imageName: '',
      croppedImage: '',
      cropperKey: 1,
      cropperMode: 'Crop',
    };
  },
  computed: {
    localResource() {
      return copy(this.resource);
    },
  },
  watch: {
    cropperMode: {
      handler(val) {
        this.$refs.cropper.setDragMode(val.toLowerCase());
      },
    },
    file: {
      handler(val) {
        const image = new Image();
        image.file = val;

        const reader = new FileReader();
        reader.onload = (event) => {
          this.imageName = event.target.result;
          this.cropperKey++;
        };
        reader.readAsDataURL(val);
        this.newImage = image;
      },
    },
  },
  async created() {
    const rawPhoto = await getResourcePhoto(
      this.resource.resourceId,
      this.resource.datasetId,
    );
  },
  methods: {
    upload() {
      const initialCanvas = this.$refs.cropper.getCroppedCanvas();
      const roundedCanvas = this.getRoundedCanvas(initialCanvas);
      roundedCanvas.toBlob((blob) => {
        // We need to convert the blob to a file
        const file = new File([blob], this.imageName, { type: 'image/*' });
        this.$emit('upload-photo', {
          resourceId: this.resource.resourceId,
          file,
          datasetId: this.resource.datasetId,
        });
      });
    },
    zoomIn() {
      this.$refs.cropper.relativeZoom(0.1);
    },
    zoomOut() {
      this.$refs.cropper.relativeZoom(-0.1);
    },
    getRoundedCanvas(sourceCanvas) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const width = sourceCanvas.width;
      const height = sourceCanvas.height;

      canvas.width = width;
      canvas.height = height;
      context.imageSmoothingEnabled = true;
      context.drawImage(sourceCanvas, 0, 0, width, height);
      context.globalCompositeOperation = 'destination-in';
      context.beginPath();
      context.arc(
        width / 2,
        height / 2,
        Math.min(width, height) / 2,
        0,
        2 * Math.PI,
        true,
      );
      context.fill();
      return canvas;
    },
  },
});
</script>

<style lang="scss">
.cropper-view-box {
  border-radius: 50%;
}
.cropper-face {
  background-color: inherit !important;
}
.flex-parent {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 2em;
}
.field-ml {
  margin-left: 3em;
}
</style>
