<template>
  <validation-observer v-slot="{ invalid }">
    <div class="modal-card" style="width: 700px">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ titleText }}</p>
      </header>
      <section class="modal-card-body">
        <validation-provider
          v-slot="{ errors }"
          rules="required"
          name="Resource ID"
        >
          <b-field horizontal label="Resource ID">
            <b-input v-model="localResource.resourceId" type="text">{{
              localResource.resourceId
            }}</b-input>
          </b-field>
          <span class="help has-text-danger">
            {{ errors[0] }}
          </span>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          rules="required"
          name="Dataset"
        >
          <b-field horizontal label="Dataset">
            <b-input v-model="localResource.datasetId" type="text">{{
              localResource.datasetId
            }}</b-input>
          </b-field>
          <span class="help has-text-danger">
            {{ errors[0] }}
          </span>
        </validation-provider>
        <validation-provider v-slot="{ errors }" rules="required" name="Name">
          <b-field horizontal label="Name">
            <b-input v-model="localResource.nameOverride">{{
              localResource.nameOverride
            }}</b-input>
          </b-field>
          <span class="help has-text-danger">
            {{ errors[0] }}
          </span>
        </validation-provider>

        <b-field horizontal label="Fun Fact">
          <b-input v-model="localResource.funFact">{{
            localResource.funFact
          }}</b-input>
        </b-field>

        <b-field horizontal label="Licence Plate">
          <b-input v-model="localResource.licencePlate">{{
            localResource.licencePlate
          }}</b-input>
        </b-field>
      </section>

      <footer class="modal-card-foot">
        <b-button type="is-primary" @click="$parent.close()"> Close </b-button>
        <b-button :disabled="invalid" type="is-primary" @click="save">
          Create
        </b-button>
      </footer>
    </div>
  </validation-observer>
</template>

<script>
import { DateTime } from 'luxon';
import { defineComponent } from '@vue/composition-api';
import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
} from 'vee-validate';

setInteractionMode('aggressive');

export default defineComponent({
  name: 'CreateResourceConfig',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      localResource: {
        resourceId: '',
        datasetId: '',
        createdDate: undefined,
        modifiedDate: undefined,
        nameOverride: '',
        funFact: undefined,
        licencePlate: '',
      },
    };
  },
  computed: {
    titleText() {
      return this.$t('Create Resource Config');
    },
    createdDate() {
      return DateTime.now();
    },
    modifiedDate() {
      return DateTime.now();
    },
  },
  methods: {
    save() {
      this.$emit('create-resource', this.localResource);
    },
  },
});
</script>
