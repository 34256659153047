var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"modal-card",staticStyle:{"width":"700px"}},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v(_vm._s(_vm.titleText))])]),_c('section',{staticClass:"modal-card-body"},[_c('validation-provider',{attrs:{"rules":"required","name":"Resource ID"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Resource ID"}},[_c('b-input',{attrs:{"type":"text"},model:{value:(_vm.localResource.resourceId),callback:function ($$v) {_vm.$set(_vm.localResource, "resourceId", $$v)},expression:"localResource.resourceId"}},[_vm._v(_vm._s(_vm.localResource.resourceId))])],1),_c('span',{staticClass:"help has-text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"Dataset"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Dataset"}},[_c('b-input',{attrs:{"type":"text"},model:{value:(_vm.localResource.datasetId),callback:function ($$v) {_vm.$set(_vm.localResource, "datasetId", $$v)},expression:"localResource.datasetId"}},[_vm._v(_vm._s(_vm.localResource.datasetId))])],1),_c('span',{staticClass:"help has-text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Name"}},[_c('b-input',{model:{value:(_vm.localResource.nameOverride),callback:function ($$v) {_vm.$set(_vm.localResource, "nameOverride", $$v)},expression:"localResource.nameOverride"}},[_vm._v(_vm._s(_vm.localResource.nameOverride))])],1),_c('span',{staticClass:"help has-text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('b-field',{attrs:{"horizontal":"","label":"Fun Fact"}},[_c('b-input',{model:{value:(_vm.localResource.funFact),callback:function ($$v) {_vm.$set(_vm.localResource, "funFact", $$v)},expression:"localResource.funFact"}},[_vm._v(_vm._s(_vm.localResource.funFact))])],1),_c('b-field',{attrs:{"horizontal":"","label":"Licence Plate"}},[_c('b-input',{model:{value:(_vm.localResource.licencePlate),callback:function ($$v) {_vm.$set(_vm.localResource, "licencePlate", $$v)},expression:"localResource.licencePlate"}},[_vm._v(_vm._s(_vm.localResource.licencePlate))])],1)],1),_c('footer',{staticClass:"modal-card-foot"},[_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.$parent.close()}}},[_vm._v(" Close ")]),_c('b-button',{attrs:{"disabled":invalid,"type":"is-primary"},on:{"click":_vm.save}},[_vm._v(" Create ")])],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }