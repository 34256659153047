<template>
  <validation-observer v-slot="{ invalid }">
    <div class="modal-card" style="width: 700px">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ titleText }}</p>
      </header>
      <section class="modal-card-body">
        <b-field horizontal :label="$t('omw-resource-config-resource-id')">
          <b-input v-model="localResource.resourceId" disabled type="text">{{
            localResource.resourceId
          }}</b-input>
        </b-field>
        <b-field horizontal :label="$t('omw-resource-config-dataset')">
          <b-input v-model="localResource.datasetId" disabled type="text">{{
            localResource.datasetId
          }}</b-input>
        </b-field>

        <validation-provider
          v-slot="{ errors }"
          rules="required"
          :name="$t('omw-resource-config-resource-name')"
        >
          <b-field horizontal :label="$t('omw-resource-config-resource-name')">
            <b-input v-model="localResource.nameOverride">{{
              localResource.nameOverride
            }}</b-input>
          </b-field>
          <span class="help has-text-danger">
            {{ errors[0] }}
          </span>
        </validation-provider>

        <b-field horizontal :label="$t('omw-resource-config-fun-fact')">
          <b-input v-model="localResource.funFact">{{
            localResource.funFact
          }}</b-input>
        </b-field>

        <b-field horizontal :label="$t('omw-resource-config-licence-plate')">
          <b-input v-model="localResource.licencePlate">{{
            localResource.licencePlate
          }}</b-input>
        </b-field>

        <b-field horizontal :label="$t('omw-resource-config-created')">
          <b-input :value="formatDate(localResource.createdDate)" disabled>
          </b-input>
        </b-field>

        <b-field horizontal :label="$t('omw-resource-config-modified')">
          <b-input
            :value="formatDate(localResource.modifiedDate)"
            disabled
          ></b-input>
        </b-field>
      </section>

      <footer class="modal-card-foot">
        <b-button type="is-primary" @click="$parent.close()">
          {{ $t('omw-resource-config-close') }}
        </b-button>
        <b-button :disabled="invalid" type="is-primary" @click="save">
          {{ $t('omw-resource-config-save') }}
        </b-button>
      </footer>
    </div>
  </validation-observer>
</template>

<script>
import { DateTime } from 'luxon';
import { defineComponent } from '@vue/composition-api';
import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
} from 'vee-validate';

setInteractionMode('aggressive');

export default defineComponent({
  name: 'UpdateResourceConfig',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    resource: {
      type: Object,
      required: false,
      default: () => {
        return {
          resourceId: '',
          datasetId: '',
          createdDate: undefined,
          modifiedDate: undefined,
          nameOverride: '',
          funFact: undefined,
          licencePlate: '',
        };
      },
    },
  },
  data() {
    return {
      localResource: {
        resourceId: '',
        datasetId: '',
        createdDate: undefined,
        nameOverride: '',
        funFact: undefined,
        licencePlate: '',
      },
    };
  },
  computed: {
    titleText() {
      return 'Update Resource Config';
    },
  },
  created() {
    this.localResource = this.$copy(this.resource);
  },
  methods: {
    formatDate(dateStr) {
      if (!dateStr) return '';
      return DateTime.fromMillis(dateStr).toLocaleString(
        DateTime.DATETIME_SHORT,
      );
    },
    save() {
      this.$emit('update-resource', {
        ...this.localResource,
        modifiedDate: DateTime.now().toISO(),
      });
    },
  },
});
</script>
