import { Auth, API } from 'aws-amplify';
import axios from 'axios';
import FileType from 'file-type/browser';
import amplifyConfig from '@/amplify-config';
import configData from '@/config/config.json';
import store from '@/store/store-modules';

export const getAllResourceConfigs = async () => {
  return API.get('omw', '/resourceConfig');
};

export const updateResourceInfo = async ({
  resourceId,
  funFact,
  nameOverride,
  licencePlate,
  datasetId,
}) => {
  const payload = {
    body: {
      datasetId,
      resourceId,
      nameOverride,
      funFact,
      licencePlate,
    },
  };
  return API.patch('omw', `/resourceConfig/${resourceId}`, payload);
};

export const createResourceConfig = async ({
  resourceId,
  funFact,
  nameOverride,
  licencePlate,
  datasetId,
}) => {
  const payload = {
    body: {
      datasetId,
      resourceId,
      nameOverride,
      funFact,
      licencePlate,
    },
  };
  return API.post('omw', '/resourceConfig', payload);
};

export const deleteResourcePhoto = async ({
  resourceId,
  funFact,
  nameOverride,
  datasetId,
}) => {
  const payload = {
    queryStringParameters: {
      datasetId,
    },
  };
  return API.del('omw', `/resourceConfig/${resourceId}/photo`, payload);
};

export const uploadResourcePhoto = async ({ resourceId, photo, datasetId }) => {
  const formData = new FormData();
  formData.append('image', photo);
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();
  const params = new URLSearchParams();
  params.append('datasetId', datasetId);

  const url =
    amplifyConfig.apiGateway.URL + `/resourceConfig/${resourceId}/photo`;
  const { data } = await axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
    params,
  });
};

export const getResourcePhoto = async (resourceId, datasetId) => {
  const payload = {
    headers: {
      accept: 'application/octet-stream',
    },
    queryStringParameters: {
      datasetId,
    },
  };
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();
  const params = new URLSearchParams();
  params.append('datasetId', datasetId);

  const url =
    amplifyConfig.apiGateway.URL + `/resourceConfig/${resourceId}/photo`;

  const { data } = await axios.get(url, {
    headers: {
      accept: 'image/jpg, image/png',
      Authorization: `Bearer ${token}`,
    },
    responseType: 'arraybuffer',
    params,
  });
  return data;
};

export const getThumb = async (resourceId, datasetId) => {
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();
  const params = new URLSearchParams();
  params.append('datasetId', datasetId);

  const url =
    amplifyConfig.apiGateway.URL + `/resourceConfig/${resourceId}/thumb`;

  const { data } = await axios.get(url, {
    headers: {
      accept: 'image/jpg, image/png',
      Authorization: `Bearer ${token}`,
    },
    responseType: 'arraybuffer',
    params,
  });
  return data;
};

export function convertBinaryToImage(rawPhoto) {
  return new Promise((resolve) => {
    if (rawPhoto?.byteLength > 0) {
      FileType.fromBuffer(rawPhoto).then((type) => {
        const blob = new Blob([rawPhoto], { type: type.mime });
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => {
          return resolve(reader.result); // data url
        };
      });
    }
  });
}

export function convertArrayBufferToImage(buffer) {
  const uint8Array = new Uint8Array(buffer);
  const data = uint8Array.reduce(
    (acc, i) => (acc += String.fromCharCode.apply(null, [i])),
    '',
  );
  return data;
}
