<template>
  <div class="container is-fluid">
    <b-table
      :data="resourceConfigs"
      style="margin-top: 1rem"
      striped
      hoverable
      :scrollable="true"
      paginated
      narrowed
      sticky-header
      searchable
      height="500px"
      :mobile-cards="false"
      :loading="loading"
    >
      <b-input
        v-if="!props.column.numeric"
        slot="searchable"
        v-model="props.filters[props.column.field]"
        slot-scope="props"
        icon="search"
        size="is-small"
      />

      <b-table-column v-slot="props" field="resourceId" width="100">
        <b-tooltip
          :active="allThumbs[props.row.resourceId] ? true : false"
          :label="$t('omw-resource-config-preview-label')"
          position="is-right"
        >
          <div @click="showImageModal(props.row)">
            <img
              v-if="allThumbs[props.row.resourceId]"
              :src="allThumbs[props.row.resourceId]"
            />
          </div>
        </b-tooltip>
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="resourceId"
        :label="$t('omw-resource-config-resource-id')"
        sortable
        searchable
        cell-class="cell-align has-text-weight-semibold is-size-6"
      >
        {{ props.row.resourceId }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="name"
        :label="$t('omw-resource-config-resource-name')"
        sortable
        searchable
        cell-class="cell-align has-text-weight-semibold is-size-6"
      >
        {{ props.row.nameOverride }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="datasetId"
        :label="$t('omw-resource-config-dataset')"
        sortable
        searchable
        cell-class="cell-align has-text-weight-semibold is-size-6"
      >
        {{ props.row.datasetId }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="funFact"
        :label="$t('omw-resource-config-fun-fact')"
        sortable
        searchable
        cell-class="cell-align has-text-weight-semibold is-size-6"
      >
        {{ props.row.funFact }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="licencePlate"
        :label="$t('omw-resource-config-licence-plate')"
        sortable
        searchable
        cell-class="cell-align has-text-weight-semibold is-size-6"
      >
        {{ props.row.licencePlate }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="createDate"
        :label="$t('omw-resource-config-created')"
        sortable
        cell-class="cell-align has-text-weight-semibold is-size-6"
      >
        {{ formatDate(props.row.createdDate) }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="modifiedDate"
        :label="$t('omw-resource-config-modified')"
        sortable
        cell-class="cell-align has-text-weight-semibold is-size-6"
      >
        {{ formatDate(props.row.modifiedDate) }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="photoName"
        label="Photo?"
        centered
        cell-class="cell-align has-text-weight-semibold is-size-6"
      >
        <b-icon
          pack="fas"
          :type="props.row.photoName ? 'is-success' : 'is-danger'"
          :icon="props.row.photoName ? 'file-check' : 'file-times'"
        >
        </b-icon>
      </b-table-column>
      <b-table-column
        v-slot="props"
        custom-key="Update"
        sortable
        centered
        cell-class="cell-align has-text-weight-semibold is-size-6"
      >
        <b-button
          type="is-success"
          size="is-small"
          @click="onUpdateClick(props.row)"
          >{{ $t('omw-resource-config-update-label') }}</b-button
        >
      </b-table-column>
      <b-table-column
        v-slot="props"
        custom-key="Upload"
        sortable
        centered
        cell-class="cell-align has-text-weight-semibold is-size-6"
      >
        <b-button
          type="is-primary"
          size="is-small"
          @click="onUploadClick(props.row)"
          >{{ $t('omw-resource-config-upload-photo-label') }}</b-button
        >
      </b-table-column>
      <b-table-column
        v-slot="props"
        custom-key="Delete"
        sortable
        centered
        cell-class="cell-align has-text-weight-semibold is-size-6"
      >
        <b-button
          type="is-danger"
          size="is-small"
          @click="handleDelete(props.row)"
          >{{ $t('omw-resource-config-delete-photo-label') }}</b-button
        >
      </b-table-column>
    </b-table>
    <div class="buttons mt-1">
      <b-button type="is-primary" @click="onCreateClick()">{{
        $t('omw-resource-config-create-new-label')
      }}</b-button>
      <b-button type="is-primary" @click="fetchData()">{{
        $t('omw-resource-config-reload')
      }}</b-button>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { DateTime } from 'luxon';

import {
  getAllResourceConfigs,
  uploadResourcePhoto,
  updateResourceInfo,
  createResourceConfig,
  deleteResourcePhoto,
  getThumb,
  convertArrayBufferToImage,
} from '@/services/resourceConfig';
import UpdateResourceConfig from './edit/UpdateResourceConfig';
import UploadResourcePhoto from './edit/UploadResourcePhoto';
import CreateResourceConfig from './edit/CreateResourceConfig';
import ImagePreview from './ImagePreview.vue';

export default defineComponent({
  name: 'DisplayResourceConfig',
  components: {},
  data() {
    return {
      resourceConfigs: [],
      loading: false,
      allThumbs: {},
      uploadModal: undefined,
      updateModal: undefined,
      createModal: undefined,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true;
        this.resourceConfigs = await getAllResourceConfigs();
        this.resourceConfigs.forEach((resource) => {
          this.getThumbnail(resource.resourceId, resource.datasetId);
        });
      } catch (err) {
        console.error(err);
        this.showToast(`Failed to retrieve data: ${err.message}`, false);
      } finally {
        this.loading = false;
      }
    },
    showImageModal(resource) {
      if (this.allThumbs[resource.resourceId]) {
        this.$buefy.modal.open({
          parent: this,
          component: ImagePreview,
          props: {
            image: this.allThumbs[resource.resourceId],
            resourceName: resource.nameOverride,
            resourceId: resource.resourceId,
          },
          hasModalCard: true,
        });
      }
    },
    formatDate(dateStr) {
      if (!dateStr) return '';
      return DateTime.fromMillis(dateStr).toLocaleString(
        DateTime.DATETIME_SHORT,
      );
    },
    onUpdateClick(resource) {
      this.updateModal = this.$buefy.modal.open({
        parent: this,
        component: UpdateResourceConfig,
        props: {
          resource: resource,
        },
        events: {
          'update-resource': this.handleUpdate,
        },
        hasModalCard: true,
      });
    },
    onCreateClick() {
      this.createModal = this.$buefy.modal.open({
        parent: this,
        component: CreateResourceConfig,
        events: {
          'create-resource': this.handleCreate,
        },
        hasModalCard: true,
      });
    },
    onUploadClick(resource) {
      this.uploadModal = this.$buefy.modal.open({
        parent: this,
        component: UploadResourcePhoto,
        'full-screen': true,
        props: {
          resource,
          currentImg: this.allThumbs[resource.resourceId],
        },
        events: {
          'upload-photo': this.handleUpload,
        },
        hasModalCard: true,
      });
    },
    async handleUpdate(resource) {
      try {
        this.loading = true;
        await updateResourceInfo(resource);
        this.showToast(`Resource ${resource.resourceId} updated`, true);
      } catch (err) {
        this.showToast(
          `Resource ${resource.resourceId} resource update`,
          false,
        );
      } finally {
        this.loading = false;
      }
      this.updateModal.close();
      this.uploadModal = undefined;
      await this.fetchData();
    },
    async handleCreate(resource) {
      try {
        this.loading = true;
        await createResourceConfig(resource);
        this.showToast(`Resource ${resource.resourceId} created`, true);
        this.createModal.close();
        this.createModal = undefined;
      } catch (err) {
        this.showToast(
          `Resource ${resource.resourceId} resource create`,
          false,
        );
      } finally {
        this.loading = false;
        await this.fetchData();
      }
    },
    async handleDelete(resource) {
      try {
        this.loading = true;
        await deleteResourcePhoto(resource);
        this.showToast(
          `Photo for resource ${resource.resourceId} deleted`,
          true,
        );
      } catch (err) {
        this.showToast(
          `Resource ${resource.resourceId} photo delete failed`,
          false,
        );
      } finally {
        this.loading = false;
      }
      await this.fetchData();
      delete this.allThumbs[resource.resourceId];
      this.$forceUpdate();
    },
    async handleUpload(resource) {
      try {
        this.loading = true;
        await uploadResourcePhoto({
          resourceId: resource.resourceId,
          photo: resource.file,
          datasetId: resource.datasetId,
        });
        this.showToast(`Resource ${resource.resourceId} photo uploaded`, true);
        this.uploadModal.close();
        this.uploadModal = null;
      } catch (err) {
        console.error(err);
        this.showToast(
          `Resource ${resource.resourceId} photo upload failed failed`,
          false,
        );
      } finally {
        this.loading = false;
      }
      await this.fetchData();
    },
    async getThumbnail(resourceId, datasetId) {
      const rawThumb = await getThumb(resourceId, datasetId);
      const img = convertArrayBufferToImage(rawThumb);
      this.$set(this.allThumbs, resourceId, img);
    },
    showToast(msg, successful) {
      this.$buefy.toast.open({
        message: msg,
        type: successful ? 'is-success' : 'is-danger',
        duration: 5000,
      });
    },
  },
});
</script>

<style lang="scss">
.cell-align {
  vertical-align: middle !important;
}
</style>
