<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ headerText }}</p>
    </header>
    <section class="modal-card-body">
      <img :src="image" />
    </section>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      required: true,
      type: String,
    },
    resourceId: {
      required: false,
      type: String,
      default: '',
    },
    resourceName: {
      required: false,
      type: String,
      default: '',
    },
  },
  computed: {
    headerText() {
      if (this.resourceId && this.resourceName) {
        return `${this.resourceId}: ${this.resourceName}`;
      }
      if (this.resourceId && !this.resourceName) {
        return this.resourceId;
      }
      if (this.resourceName) {
        return this.resourceName;
      }
      return '';
    },
  },
};
</script>

<style></style>
